<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-14 14:06:57
 * @LastEditors: pj
 * @LastEditTime: 2022-11-30 09:58:26
-->
<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-07 15:37:39
 * @LastEditors: pj
 * @LastEditTime: 2022-11-11 11:41:38
-->
<template>
  <div class="upload">
    <div class="upload-top">
      <img src="../../assets/logo.png" />
    </div>
    <div class="upload-content">
      <img src="../../assets/downBg.png" style="width: 100%" alt="" />
      <div class="upload-content-work">
        <div class="work-from">
          <h1>填写报名信息</h1>
          <h4>填写并提交报名信息后即可上传参赛作品</h4>
          <h4>温馨提示：报名信息填写后不能修改，请谨慎填写哦！</h4>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="参赛组别" prop="opusType">
              <el-select
                v-model="ruleForm.opusType"
                placeholder="请选择参赛选组"
                style="width: 607px"
              >
                <el-option label="专业组" value="1"></el-option>
                <el-option label="学生组" value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="参赛姓名" prop="linkName">
              <el-input
                v-model="ruleForm.linkName"
                placeholder="填写姓名"
              ></el-input>
            </el-form-item>

            <el-form-item label="联系方式" prop="linkPhone">
              <el-input
                v-model="ruleForm.linkPhone"
                placeholder="填写联系号码"
              ></el-input>
            </el-form-item>

            <el-form-item label="电子邮箱" prop="linkMail">
              <el-input
                v-model="ruleForm.linkMail"
                placeholder="填写电子邮箱（如Taoshi8@163.com）"
              ></el-input>
            </el-form-item>

            <el-form-item label="收货地址" prop="linkAddress">
              <el-input
                v-model="ruleForm.linkAddress"
                placeholder="填写地址（仅用于发放奖励使用）"
              ></el-input>
            </el-form-item>

            <div class="line-border"></div>

            <el-form-item label="作品名称" prop="opusTitle">
              <el-input
                v-model="ruleForm.opusTitle"
                placeholder="输入作品名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="作品描述" prop="opusContacts">
              <el-input
                type="textarea"
                v-model="ruleForm.opusContacts"
                placeholder="输入500字以内的作品描述（描述角色观会加分）"
              ></el-input>
            </el-form-item>

            <div class="line-border"></div>

            <div style="display: flex; align-items: flex-end">
              <div style="font-size: 12px; color: #0e0e0e; line-height: 12px">
                上传封面图
              </div>
              <div
                style="
                  font-size: 8px;
                  color: #afafaf;
                  line-height: 12px;
                  margin-left: 10px;
                "
              >
                注：封面图建议4:3比例上传，展示效果更佳
              </div>
            </div>

            <div class="line-border"></div>

            <div class="work-from-productCover">
              <el-upload
                action="https://api.tanoshi.cc/nft_app/distapi/file/file/uploadImage"
                list-type="picture-card"
                :limit="1"
                :http-request="uploadFile"
                :before-upload="beforeUpload"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>

            <div class="line-border"></div>

            <div style="display: flex; align-items: flex-end">
              <div style="font-size: 12px; color: #0e0e0e; line-height: 12px">
                上传作品图
              </div>
              <div
                style="
                  font-size: 8px;
                  color: #afafaf;
                  line-height: 12px;
                  margin-left: 10px;
                "
              >
                注：最多九张
              </div>
            </div>

            <div class="work-from-productCover">
              <el-upload
                action="https://api.tanoshi.cc/nft_app/distapi/file/file/uploadImage"
                list-type="picture-card"
                :on-remove="handleRemove"
                :limit="9"
                :http-request="uploadFileList"
                :before-upload="beforeUpload"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>

            <div class="line-border"></div>

            <el-form-item>
              <div class="button">
                <el-checkbox v-model="checked" style="margin-bottom: 20px"
                  >我已同意《参赛条款与作品权利》和身份证收集等所有条款</el-checkbox
                >

                <el-button
                  type="primary"
                  style="width: 324px"
                  @click="submitForm('ruleForm')"
                  >立即上传</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveOpus } from '@/api/sigh/index.js'
export default {
  name: 'upload',
  components: {},
  data() {
    return {
      ruleForm: {
        linkName: '',
        linkPhone: '',
        opusType: '',
        linkMail: '',
        linkAddress: '',
        opusContacts: '',
        opusTitle: '',
      },
      // 允许的文件类型
      fileType: ['png', 'jpg', 'gif'],
      //请求头
      headers: { 'Content-Type': 'multipart/form-data' },
      productCover: '',
      productList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      checked: true,
      rules: {
        linkName: [
          { required: true, message: '请输入名字', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        opusType: [
          { required: true, message: '请选择参加的组别', trigger: 'change' },
        ],
        linkPhone: [
          { required: true, message: '请填写联系号码', trigger: 'blur' },
          { min: 1, max: 99, message: '请填写联系号码', trigger: 'blur' },
        ],
        linkMail: [
          { required: true, message: '请填写电子邮箱', trigger: 'blur' },
          { min: 1, max: 99, message: '请填写电子邮箱', trigger: 'blur' },
        ],
        linkAddress: [
          { required: true, message: '请填写地址', trigger: 'blur' },
          { min: 1, max: 99, message: '请填写地址', trigger: 'blur' },
        ],
        opusTitle: [
          { required: true, message: '请输入作品名称', trigger: 'blur' },
          {
            min: 4,
            max: 50,
            message: '长度在 4 到 50 个字符',
            trigger: 'blur',
          },
        ],
        opusContacts: [
          {
            required: true,
            message: '请填写500字以内的作品描述',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 500,
            message: '请填写500字以内的作品描述',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.productCover === '' || this.productList.length === 0) {
            this.$message({
              message: '请按照要求上传作品!',
              type: 'warning',
            })
          } else if (!this.checked) {
            this.$message({
              message: '请勾选!',
              type: 'warning',
            })
          } else {
            const socialOpusPicPOList = []
            const coverImgs = {
              pictureUrl: this.productCover,
              type: 1,
              sort: 1,
            }
            socialOpusPicPOList.push(coverImgs)
            this.productList.forEach((item, index) => {
              for (let key in item) {
                let imgObj = {
                  pictureUrl: item[key],
                  type: 2,
                  sort: index + 2,
                }
                socialOpusPicPOList.push(imgObj)
                break
              }
            })
            let params = { ...this.ruleForm, socialOpusPicPOList }
            const result = await saveOpus(params)
            this.$message({
              message: '上传成功',
              type: 'success',
            })
            if (result.code === 200) {
              this.$router.push({ path: '/home' })
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },

    handleRemove(file, fileList) {
      console.log(file.uid, fileList)
      console.log(this.productList, 'this.productList')
      for (let i = 0; i <= this.productList.length; i++) {
        if (file.uid in this.productList[i]) {
          this.productList.splice(i, 1)
          console.log(this.productList, 'this.productList')
          return
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != '' || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, '').toLowerCase()
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 2 //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$message({
            message: '上传文件大小不能超过 2MB!',
            type: 'warning',
          })
          return false
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true
        } else {
          this.$message({
            message: '上传文件格式不正确!',
            type: 'warning',
          })
          return false
        }
      }
    },
    // 上传封面图
    uploadFile(item) {
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      axios({
        method: 'post',
        url: 'https://api.tanoshi.cc/nft_app/api/file/file/uploadImage',
        headers: this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then((res) => {
        if (res.data.code === 200) {
          this.productCover = res.data.data
          this.$message({
            message: '上传成功',
            type: 'success',
          })
        }
      })
    },
    uploadFileList(item) {
      console.log(item, 'item')
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      axios({
        method: 'post',
        url: 'https://api.tanoshi.cc/nft_app/api/file/file/uploadImage',
        headers: this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then((res) => {
        if (res.data.code === 200) {
          let uid = item.file.uid
          let param = {}
          param[uid] = res.data.data
          this.productList.push(param)
          console.log(this.productList, 'this.productList')
          this.$message({
            message: '上传成功',
            type: 'success',
          })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.upload {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /deep/ .el-form .el-form-item .el-form-item__label {
    font-size: 12px !important;
  }
  .line-border {
    width: 100%;
    height: 1px;
    background-color: #ececec;
    margin: 40px 0 40px 0;
  }
  &-top {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    image {
      width: 162rpx;

      height: 51rpx;
    }
  }
  &-content {
    width: 100%;
    position: relative;
    // /deep/ .el-button {
    //   justify-content: center;
    // }
    &-work {
      width: 100%;
      position: absolute;
      top: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      .work-from {
        width: 1074px;
        height: 1450px;
        background: #fdfdfd;
        display: flex;
        flex-direction: column;
        align-items: center;
        .demo-ruleForm {
          .work-from-productCover {
            margin-bottom: 20px;
            /deep/ .el-icon-plus::after {
              content: '支持jpg/gif/png';
            }
          }
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-left: -50px;
      }
    }
  }
}
</style>
